import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { FaUsers, FaHashtag } from 'react-icons/fa';
import HandDrawnUnderline from '../ui/HandDrawnUnderline';

const WhereToPlay = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <section className="p-2 px-1 md:p-8">
      <h2 className="text-2xl font-bold mb-6 pl-1 relative inline-block" style={{ color: theme.textColor }}>
        <span>Market Positioning</span>
        <HandDrawnUnderline color={theme.primaryColor} />
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Customer Focus */}
        <div className="rounded-xl p-2 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-2 mb-2">
            <FaUsers className="text-xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Customer Focus</h3>
          </div>
          <div className="space-y-3" style={{ color: theme.textColor }}>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <strong>Businesses:</strong> Underserved SMEs and early-stage startups seeking growth capital
              </li>
              <li>
                <strong>Lead Investors:</strong> Sector experts with deal flow and validation capability
              </li>
              <li>
                <strong>Retail Investors:</strong> Active portfolio builders seeking diversification, high-risk tolerance investors pursuing growth, and entrepreneurs/founders looking to invest in their industry
              </li>
            </ul>
          </div>
        </div>

        {/* Social Networks Presence */}
        <div className="rounded-xl p-2 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-2 mb-2">
            <FaHashtag className="text-xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Social Networks Presence</h3>
          </div>
          <div className="space-y-3" style={{ color: theme.textColor }}>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <strong>LinkedIn:</strong> Business development & lead investors network building, thought leadership content, and industry insights sharing
              </li>
              <li>
                <strong>Instagram, X, Facebook, Blossom:</strong> Building and engaging the retail investor community through educational content, success stories, real-time updates, and investment opportunities
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhereToPlay;
