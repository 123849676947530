import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { User, LogIn, LogOut, Rocket, Layout, FileSpreadsheet } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';
import SteerUpLogo from '../components/SteerUpLogo';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';

const NavItem = ({ icon: Icon, label, to, onClick, children }) => {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);

  if (children) {
    return (
      <div className="relative">
        <button
          className="flex items-center p-2 text-textLight"
          onClick={() => setIsOpen(!isOpen)}
          style={{ color: theme.textColor }}
        >
          <Icon className="w-6 h-6 mr-2" />
          <span>{label}</span>
        </button>
        {isOpen && (
          <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex flex-col items-center p-2 ${isActive ? 'text-secondary' : 'text-textLight'}`
      }
      style={({ isActive }) => ({
        color: isActive ? theme.secondaryColor : theme.textColor,
      })}
      onClick={onClick}
    >
      <Icon className="w-6 h-6 mb-1" />
      <span className="text-xs">{label}</span>
    </NavLink>
  );
};

const Navigation = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { t } = useTranslation(['common', 'features', 'playgrounds']);
  const isMobileBottomNavEnabled = process.env.REACT_APP_ENABLE_MOBILE_BOTTOM_NAV === 'true';
  const isDevRoutesEnabled = process.env.REACT_APP_ENABLE_DEV_ROUTES === 'true';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const navItems = [
    { icon: Rocket, label: 'Strategic Playbook', to: '/business/steerup/playbook', requiresAuth: false },
    ...(isDevRoutesEnabled ? [{ icon: Layout, label: t('playgrounds:title'), to: '/playgrounds', requiresAuth: false }] : []),
    { icon: FileSpreadsheet, label: t('features:financingJourney.title'), to: '/financing-journey', requiresAuth: true },
    { icon: User, label: t('common:profile'), to: '/profile', requiresAuth: true },
  ];

  const filteredNavItems = user
    ? navItems
    : navItems.filter(item => !item.requiresAuth);

  const authItem = user
    ? { icon: LogOut, label: t('common:logout'), onClick: handleSignOut }
    : isDevRoutesEnabled 
      ? { icon: LogIn, label: t('common:login'), to: '/auth' }
      : null;

  const renderNavItems = (items, mobile = false) => {
    return items.map((item) => {
      if (item.children) {
        return (
          <NavItem key={item.label} {...item}>
            {item.children.map((child) => (
              <Link
                key={child.label}
                to={child.to}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {child.label}
              </Link>
            ))}
          </NavItem>
        );
      }
      return <NavItem key={item.label} {...item} />;
    });
  };

  return (
    <>
      {/* Mobile Top Bar - Always visible on mobile */}
      <div className="md:hidden fixed top-0 left-0 right-0 p-4 z-50 flex justify-between items-center" style={{ backgroundColor: theme.appBackgroundColor }}>
        <Link to="/" className="w-32">
          <SteerUpLogo color={theme.primaryColor} />
        </Link>
        <LanguageSwitcher />
      </div>

      {/* Mobile Bottom Navigation */}
      {isMobileBottomNavEnabled && (
        <nav className="md:hidden fixed bottom-0 left-0 right-0 z-50" style={{ backgroundColor: theme.appBackgroundColor }}>
          <div className="flex justify-around">
            {renderNavItems(filteredNavItems, true)}
            {authItem && <NavItem {...authItem} />}
          </div>
        </nav>
      )}

      {/* Desktop Navigation */}
      <nav className="hidden md:flex fixed top-0 left-0 right-0 p-4 z-50" style={{ backgroundColor: theme.appBackgroundColor }}>
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="w-40 -ml-4">
            <SteerUpLogo color={theme.primaryColor} />
          </Link>
          <div className="flex space-x-4 items-center">
            {renderNavItems(filteredNavItems)}
            {authItem && (
              <NavLink
                to={authItem.to}
                onClick={authItem.onClick}
                className="flex items-center px-3 py-2 rounded-full"
                style={{ color: theme.textColor }}
              >
                <authItem.icon className="w-5 h-5 mr-2" />
                {authItem.label}
              </NavLink>
            )}
            <LanguageSwitcher />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
