import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { FaTrophy } from 'react-icons/fa';
import HandDrawnUnderline from '../ui/HandDrawnUnderline';

const HowToWin = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { theme } = useContext(ThemeContext);

  return (
    <section className="p-2 px-1 md:p-8">
      <h2 className="text-2xl font-bold mb-6 pl-1 relative inline-block" style={{ color: theme.textColor }}>
        <span>Path to Success</span>
        <HandDrawnUnderline color={theme.primaryColor} />
      </h2>

      {/* Execution Roadmap */}
      <div className="mb-4">

        <div className="grid grid-cols-1 2xl:grid-cols-5 gap-2">
          {/* Go-to-Market Phase - Active */}
          <div 
            className="rounded-xl p-3 relative overflow-hidden transition-all duration-300"
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              borderLeft: `4px solid ${theme.primaryColor}`,
              boxShadow: `0 0 20px ${theme.primaryColor}20`
            }}
          >
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="text-base font-bold relative inline-block focus:outline-none transition-colors"
                  style={{ color: theme.primaryColor }}
                >
                  Go-to-Market
                </button>
                {!isExpanded && (
                  <button
                    onClick={() => setIsExpanded(true)}
                    className="focus:outline-none hover:opacity-80 transition-opacity"
                    aria-label="Show strategy"
                  >
                    <svg
                      className="w-5 h-5 bounce-animation"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4L12 20M12 20L18 14M12 20L6 14"
                        stroke={theme.primaryColor}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <span className="text-sm" style={{ color: theme.textColor }}>2024-2025</span>
            </div>
            <p className="text-sm leading-5" style={{ color: theme.textColor }}>
              Build targeted communities and validate initial lead investors' deal pipelines for future crowdfunding campaigns
            </p>

            {isExpanded && (
              <div
                className="mt-4 pt-4 border-t"
                style={{
                  borderColor: theme.primaryColor + '40',
                  animation: 'slideDown 0.5s ease-out'
                }}
              >
                <div className="space-y-2" style={{ color: theme.textColor }}>
                  <p>The primary objective is to validate SteerUp's ability to execute 1-2 successful crowdfunding campaigns with 500-1000 retail investors. To do so, we will launch "SteerUp Pre-Campaign Vetting" Early 2025 in order to:</p>
                  <ul className="list-disc pl-4 space-y-2">
                    <li>Test and refine retail investor targeting methodology</li>
                    <li>Validate investor interest before full campaign launch</li>
                    <li>Iterate on marketing strategy based on investor feedback and engagement metrics</li>
                    <li>Build a qualified pipeline of potential retail investors</li>
                    <li>Assess investment appetite and preferences across different sectors</li>
                    <li>Optimize communication and education materials based on investor interactions</li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          {/* Crawl Phase */}
          <div
            className="rounded-xl p-3 relative overflow-hidden transition-all duration-300"
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              borderLeft: '4px solid transparent',
              boxShadow: `0 0 20px ${theme.primaryColor}20`
            }}
          >
            <div className="flex justify-between items-center mb-1">
              <h4 className="text-base font-bold" style={{ color: theme.textColor }}>Crawl</h4>
              <span className="text-sm" style={{ color: theme.textColor }}>2025</span>
            </div>
            <p className="text-sm" style={{ color: theme.textColor }}>
              Execute successful crowdfunding campaigns ($250K-1M) across single or multiple sector investment tracks
            </p>
          </div>

          {/* Walk Phase */}
          <div
            className="rounded-xl p-3 relative overflow-hidden transition-all duration-300"
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              borderLeft: '4px solid transparent',
              boxShadow: `0 0 20px ${theme.primaryColor}20`
            }}
          >
            <div className="flex justify-between items-center mb-1">
              <h4 className="text-base font-bold" style={{ color: theme.textColor }}>Walk</h4>
              <span className="text-sm" style={{ color: theme.textColor }}>2026</span>
            </div>
            <p className="text-sm" style={{ color: theme.textColor }}>
              Establish multiple lead investors operating at different financing stages within sector investment tracks
            </p>
          </div>

          {/* Run Phase */}
          <div
            className="rounded-xl p-3 relative overflow-hidden transition-all duration-300"
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              borderLeft: '4px solid transparent',
              boxShadow: `0 0 20px ${theme.primaryColor}20`
            }}
          >
            <div className="flex justify-between items-center mb-1">
              <h4 className="text-base font-bold" style={{ color: theme.textColor }}>Run</h4>
              <span className="text-sm" style={{ color: theme.textColor }}>2027</span>
            </div>
            <p className="text-sm" style={{ color: theme.textColor }}>
              Emerge as the go-to platform for SMEs and early-stage startups crowdfunding
            </p>
          </div>

          {/* Fly Phase */}
          <div
            className="rounded-xl p-3 relative overflow-hidden transition-all duration-300"
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              borderLeft: '4px solid transparent',
              boxShadow: `0 0 20px ${theme.primaryColor}20`
            }}
          >
            <div className="flex justify-between items-center mb-1">
              <h4 className="text-base font-bold" style={{ color: theme.textColor }}>Fly</h4>
              <span className="text-sm" style={{ color: theme.textColor }}>2030</span>
            </div>
            <p className="text-sm" style={{ color: theme.textColor }}>
              Launch SteerUp ETFs to provide public market investors broad exposure to Canadian SMEs and startups
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {/* Competitive Edge */}
        <div className="rounded-xl p-2 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-2 mb-2">
            <FaTrophy className="text-2xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Competitive Edge</h3>
          </div>
          <div className="space-y-2" style={{ color: theme.textColor }}>
            <ul className="list-disc pl-4 space-y-2">
              <li><strong>Market Disruption</strong> - Just-in-time sector investment track deal flows vs rigid fund structures</li>
              <li><strong>Brand Trust</strong> - Lead investors with skin in the game combined with authentic storytelling fostering communities that drive organic ambassador growth</li>
              <li><strong>Network Power</strong> - Growing network of engaged retail investors vs traditional reliance on a few high net worth individuals</li>
              <li><strong>Regulatory Leverage</strong> - Fully compliant with Canadian crowdfunding regulations, no dealer/broker registration required</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToWin;

/* CSS animations */
const styles = `
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .bounce-animation {
    animation: bounce 2s infinite ease-in-out;
  }
`;

// Add styles to document
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement('style');
  styleSheet.textContent = styles;
  document.head.appendChild(styleSheet);
}
