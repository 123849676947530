import React, { useContext, useEffect, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import i18n from './i18n';
import Navigation from './pages/Navigation';
import AuthPage from './pages/AuthPage';
import Dashboard from './pages/Dashboard';
import ExplorePage from './pages/ExplorePage';
import InvestmentsPage from './pages/InvestmentsPage';
import ProfilePage from './pages/ProfilePage';
import SelectionDetailsPage from './pages/SelectionDetailsPage';
import StartupProfilePage from './pages/StartupProfilePage';
import ContributionPage from './pages/ContributionPage';
import PaymentProcessingPage from './pages/PaymentProcessingPage';
import ConfirmationPage from './pages/ConfirmationPage';
import ExpertProfilesPage from './pages/ExpertProfilesPage';
import AddLeadInvestorPage from './pages/AddLeadInvestorPage';
import AddStartupPage from './pages/AddStartupPage';
import AddStartupSelectionPage from './pages/AddStartupSelectionPage';
import FinancingJourneyPage from './pages/FinancingJourneyPage';
import ExploreSelections from './pages/ExploreSelections';
import PlaygroundsPage from './pages/PlaygroundsPage';
import LeadInvestorsPage from './pages/LeadInvestorsPage';
import BusinessPlaybookPage from './pages/BusinessPlaybookPage';
import ColorCustomizer from './components/ColorCustomizer';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import { FiSettings } from 'react-icons/fi';
import { ThemeContext } from './ThemeContext';
import { AuthProvider } from './AuthContext';

// Loading component for suspense fallback
const Loader = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="flex items-center justify-center h-screen" style={{ backgroundColor: theme.appBackgroundColor }}>
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2" style={{ borderColor: theme.primaryColor }}></div>
    </div>
  );
};

function App() {
  const { theme } = useContext(ThemeContext);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const isColorPickerEnabled = process.env.REACT_APP_ENABLE_COLOR_PICKER === 'true';
  const isDevRoutesEnabled = process.env.REACT_APP_ENABLE_DEV_ROUTES === 'true';

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.appBackgroundColor;
  }, [theme.appBackgroundColor]);

  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<Loader />}>
          <AuthProvider>
          <div className="min-h-screen flex flex-col" style={{ color: theme.textColor, backgroundColor: theme.appBackgroundColor }}>
            <Navigation />
            <main className="flex-grow pt-24">
              <Routes>
                <Route path="/" element={<Navigate to="/lead-investors" replace />} />
                <Route path="/lead-investors" element={<LeadInvestorsPage />} />
                <Route path="/business/:businessName/playbook" element={<BusinessPlaybookPage />} />
                {isDevRoutesEnabled && (
                  <>
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/playgrounds" element={<PlaygroundsPage />} />
                  </>
                )}
                <Route path="/selection/:id" element={<SelectionDetailsPage />} />
                <Route path="/startup/:id" element={<StartupProfilePage />} />
                <Route path="/experts" element={<ExpertProfilesPage />} />

                {/* Protected Routes */}
                <Route path="/explore" element={<ProtectedRoute><ExplorePage /></ProtectedRoute>} />
                <Route path="/explore-selections" element={<ProtectedRoute><ExploreSelections /></ProtectedRoute>} />
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/investments" element={<ProtectedRoute><InvestmentsPage /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path="/contribute/:id" element={<ProtectedRoute><ContributionPage /></ProtectedRoute>} />
                <Route path="/payment-processing" element={<ProtectedRoute><PaymentProcessingPage /></ProtectedRoute>} />
                <Route path="/confirmation" element={<ProtectedRoute><ConfirmationPage /></ProtectedRoute>} />
                <Route path="/add-lead-investor" element={<ProtectedRoute><AddLeadInvestorPage /></ProtectedRoute>} />
                <Route path="/add-startup" element={<ProtectedRoute><AddStartupPage /></ProtectedRoute>} />
                <Route path="/add-startup-selection" element={<ProtectedRoute><AddStartupSelectionPage /></ProtectedRoute>} />
                <Route path="/financing-journey" element={<ProtectedRoute><FinancingJourneyPage /></ProtectedRoute>} />
              </Routes>
            </main>

            <Footer />

            {/* Floating Color Picker Button */}
            {isColorPickerEnabled && (
              <div className="fixed bottom-4 right-4 z-50">
                <button
                  onClick={toggleColorPicker}
                  className="bg-primary rounded-full p-3 shadow-lg text-white"
                  style={{ backgroundColor: theme.primaryColor }}
                >
                  <FiSettings className="w-6 h-6" />
                </button>
              </div>
            )}

            {/* Color Picker Dropdown */}
            {isColorPickerEnabled && showColorPicker && (
              <div className="fixed bottom-16 right-4 bg-gray-800 p-4 rounded-lg shadow-lg z-50">
                <ColorCustomizer />
              </div>
            )}
          </div>
          </AuthProvider>
        </Suspense>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default App;
