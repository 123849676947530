import React, { useContext } from 'react';
import { FaUsers, FaRobot } from 'react-icons/fa';
import { GiAchievement } from 'react-icons/gi';
import { ThemeContext } from '../../ThemeContext';
import HandDrawnUnderline from '../ui/HandDrawnUnderline';
import CoFounderCard from '../CoFounderCard';

export default function KeyAssets() {
  const { theme } = useContext(ThemeContext);

  return (
    <section className="p-2 px-1 md:p-8">
      <h2
        className="text-2xl font-bold mb-6 pl-1 relative inline-block"
        style={{ color: theme.textColor }}
      >
        <span>Key Assets</span>
        <HandDrawnUnderline color={theme.primaryColor} />
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
        {/* Domain Authority */}
        <div className="rounded-xl p-2 bg-opacity-10 md:col-span-12" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-3 mb-2">
            <GiAchievement className="text-2xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Domain Authority</h3>
          </div>
          <div className="flex flex-wrap gap-1.5 md:gap-2">
            {[
              'financial expertise',
              'crowdfunding',
              'consumer tech',
              'product management',
              'web dev',
              'mobile dev',
              'hardware dev'
            ].map((domain, index) => (
              <span
                key={index}
                className="px-2 py-0.5 md:py-1 rounded-full text-xs bg-[#30363D] text-white font-medium"
              >
                {domain}
              </span>
            ))}
          </div>
        </div>

        {/* AI & Technology Advantage */}
        <div className="rounded-xl p-2 bg-opacity-10 md:col-span-12" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-3 mb-2">
            <FaRobot className="text-2xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>AI & Technology Advantage</h3>
          </div>
          <div className="space-y-2" style={{ color: theme.textColor }}>
            <ul className="list-disc pl-4 space-y-2">
              <li><strong>AI Marketing Engine</strong> - Generation of compelling marketing materials and content for crowdfunding campaigns</li>
              <li><strong>Deal Flow Optimization</strong> - AI-driven cost of financing optimization combined with matching of investors with opportunities</li>
              <li><strong>Financial Intelligence</strong> - Deep financial data modeling and analysis powered by AI for comprehensive business insights</li>
            </ul>
          </div>
        </div>

        {/* Founding Team */}
        <div className="rounded-xl bg-opacity-10 md:col-span-12" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-3 mb-2 pl-2">
            <FaUsers className="text-2xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Founding Team</h3>
          </div>
          <div className="grid grid-cols-1 gap-1">
            <CoFounderCard
              name="Danny Ferrin"
              title="CEO"
              experience="10 y+ in fintech"
              credentials="CFA, BEng Electrical Engineering"
              profilePic="images/startups/steerup/danny-ferrin-profile-picture.png"
              linkedinUrl="https://www.linkedin.com/in/dannyferrin/"
            />
            <CoFounderCard
              name="Jonathan Thuot"
              title="COO"
              experience="10 y+ in consumer tech & crowdfund..."
              credentials="BEng Mechanical Engineering"
              profilePic="images/startups/steerup/jonathan-thuot-profile-picture.png"
              linkedinUrl="https://www.linkedin.com/in/jonathan-thuot-a9a27313"
            />
            <CoFounderCard
              name="Olivier Henley"
              title="CTO"
              experience="10 y+ in hardware & software dev"
              credentials="BEng Electrical Engineering"
              profilePic="images/startups/steerup/olivier-henley-profile-picture.jpeg"
              linkedinUrl="https://www.linkedin.com/in/olivier-henley-6ab13024"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
