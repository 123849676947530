import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { FaLightbulb, FaRocket } from 'react-icons/fa';
import HandDrawnUnderline from '../ui/HandDrawnUnderline';

const WinningAspiration = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <section className="px-1 py-2 md:p-8">
      <h2 className="text-2xl font-bold mb-6 pl-1 relative inline-block" style={{ color: theme.textColor }}>
        <span>Winning Aspiration</span>
        <HandDrawnUnderline color={theme.primaryColor} />
      </h2>

      {/* Vision */}
      <div className="rounded-xl p-2 mb-4 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
        <div className="flex items-center gap-2 mb-2">
          <FaLightbulb className="text-xl" style={{ color: theme.primaryColor }} />
          <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>
            Vision
          </h3>
        </div>
        <div className="space-y-2">
          <p style={{ color: theme.textColor }}>
            Build stronger Canadian industries by uniting leaders, SMEs, startups, and investors in investment tracks
          </p>
        </div>
      </div>

      {/* Target Position */}
      <div className="rounded-xl p-2 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
        <div className="flex items-center gap-2 mb-2">
          <FaRocket className="text-xl" style={{ color: theme.primaryColor }} />
          <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>
            Target Position
          </h3>
        </div>
        <div style={{ color: theme.textColor }}>
          <p>
            Become the go-to platform for growing Canadian SMEs and early-stage startups, powering the next generation of industry leaders
          </p>
        </div>
      </div>
    </section>
  );
};

export default WinningAspiration;
