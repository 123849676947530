import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { FaMoneyBillWave, FaBalanceScale } from 'react-icons/fa';
import HandDrawnUnderline from '../ui/HandDrawnUnderline';

const PricePoint = ({ amount, category, theme }) => (
  <div className="flex items-center gap-2">
    <span className="text-2xl md:text-3xl font-bold">{amount}</span>
    {category && (
      <span 
        className="text-xs self-end mb-1 ml-1" 
        style={{ color: theme.textColor, opacity: 0.5 }}
      >
        {category}
      </span>
    )}
  </div>
);

const RevenueCard = ({ title, pricePoints, pricingPower, theme }) => {
  return (
    <div
      className="flex flex-col items-center p-2 md:p-4 rounded-xl w-full"
      style={{
        backgroundColor: theme.cardBgColor,
        borderLeft: `4px solid ${theme.primaryColor}40`
      }}
    >
      <h3 className="text-xl mb-1 md:mb-4" style={{ color: theme.primaryColor }}>
        {title}
      </h3>
      <div className="flex items-center justify-between w-full mt-2">
        <div className="w-[45%] flex flex-col items-center justify-center gap-2" style={{ color: theme.textColor }}>
          {Array.isArray(pricePoints) ? (
            pricePoints.map((point, index) => (
              <PricePoint
                key={index}
                amount={point.amount}
                category={point.category}
                theme={theme}
              />
            ))
          ) : (
            <PricePoint
              amount={pricePoints.amount}
              theme={theme}
            />
          )}
        </div>
        <div className="w-px h-20 mx-2" style={{ backgroundColor: `${theme.primaryColor}40` }}></div>
        <div className="w-[60%] flex flex-col justify-center text-center" style={{ color: theme.textColor }}>
          <span className="text-sm font-bold block mb-2">Pricing Power</span>
          <span className="text-xs leading-relaxed block">{pricingPower}</span>
        </div>
      </div>
    </div>
  );
};

const ProfitEngine = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <section className="p-2 px-1 md:p-8">
      <h2 className="text-2xl font-bold mb-6 pl-1 relative inline-block" style={{ color: theme.textColor }}>
        <span>Profit Engine</span>
        <HandDrawnUnderline color={theme.primaryColor} />
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        {/* Revenue Streams */}
        <div className="md:col-span-6 space-y-2">
          <div className="flex items-center gap-3">
            <FaMoneyBillWave className="text-xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Revenue Streams</h3>
          </div>
          <div className="flex flex-col gap-4 md:gap-6 w-full">
            <RevenueCard
              title="Retail Investors Annual Membership"
              pricePoints={{ amount: "$10+" }}
              pricingPower="Access to lead investors with skin in the game"
              theme={theme}
            />
            <RevenueCard
              title="Lead Investors Annual Membership"
              pricePoints={{ amount: "$5,000+" }}
              pricingPower="Access to just-in-time capital with increased control"
              theme={theme}
            />
            <RevenueCard
              title="Successful Crowdfunding Fees"
              pricePoints={[
                { amount: "8.5%", category: "on equity" },
                { amount: "6%+", category: "on debt" }
              ]}
              pricingPower="Hassle-free crowdfunding and reduced cost of financing"
              theme={theme}
            />
          </div>
        </div>

        {/* Cost Structure */}
        <div className="md:col-span-6 rounded-xl p-2 bg-opacity-10" style={{ backgroundColor: theme.secondaryBackgroundColor }}>
          <div className="flex items-center gap-3 mb-2">
            <FaBalanceScale className="text-xl" style={{ color: theme.primaryColor }} />
            <h3 className="text-lg font-semibold" style={{ color: theme.textColor }}>Cost Structure</h3>
          </div>
          <div className="space-y-2" style={{ color: theme.textColor }}>
            <ul className="list-disc pl-4 space-y-2">
              <li><strong>Fixed Costs</strong> - Platform development, infrastructure, and core team</li>
              <li><strong>Variable Costs</strong> - Lead & retail investor acquisition, crowdfunding marketing and investors relations</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfitEngine;
