import React from 'react';
import PropTypes from 'prop-types';

const HandDrawnUnderline = ({ color, height = '0.7rem', className = '', strokeWidth = 6 }) => {
  return (
    <svg
      className={`absolute -bottom-1 left-0 w-full ${className}`}
      viewBox="0 0 200 24"
      preserveAspectRatio="none"
      style={{ height }}
    >
      <path
        d="M3,12 Q50,16 100,12 T197,12"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        style={{
          transform: 'rotate(-0.5deg)',
          transformOrigin: 'center'
        }}
      />
    </svg>
  );
};

HandDrawnUnderline.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  strokeWidth: PropTypes.number
};

export default HandDrawnUnderline;
