import React, { useContext } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';
import AudioPlayer from '../components/audio/AudioPlayer';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ThemeContext } from '../ThemeContext';
import { FiMapPin, FiLinkedin, FiMail } from 'react-icons/fi';
import { BiShareAlt, BiCopy } from 'react-icons/bi';
import { BsRocket } from 'react-icons/bs';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import { FaFacebook, FaXTwitter } from 'react-icons/fa6';
import HandDrawnUnderline from '../components/ui/HandDrawnUnderline';
import WinningAspiration from '../components/business-playbook/WinningAspiration';
import WhereToPlay from '../components/business-playbook/WhereToPlay';
import HowToWin from '../components/business-playbook/HowToWin';
import ProfitEngine from '../components/business-playbook/ProfitEngine';
import KeyAssets from '../components/business-playbook/KeyAssets';

export default function BusinessPlaybookPage() {
  const { theme } = useContext(ThemeContext);
  const { businessName } = useParams();

  const formattedBusinessName = "SteerUp";

  const description =
    'SteerUp is a just-in-time equity and debt crowdfunding platform identifying business selections through lead investors who have skin in the game.';

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{`${formattedBusinessName} - Business Playbook`}</title>
        <meta name="description" content={description} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${formattedBusinessName} - Business Playbook`} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="SteerUp" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${window.location.origin}/images/startups/steerup/steerup-512.png`}
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${formattedBusinessName} - Business Playbook`} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/images/startups/steerup/steerup-512.png`}
        />
      </Helmet>
      <div
        className="w-full min-h-screen px-1 md:px-8 lg:px-12 xl:px-16"
        style={{ backgroundColor: theme.appBackgroundColor }}
      >
        <div className="max-w-[1920px] mx-auto pb-24">
          {/* Header */}
          <div className="text-center mb-14">
            <h1
              className="text-4xl md:text-5xl font-bold mb-2 flex items-center justify-center gap-2"
              style={{ color: theme.textColor }}
            >
              SteerUp
            </h1>
            <h2
              className="text-3xl md:text-4xl font-bold relative block mb-6"
              style={{ color: theme.textColor }}
            >
              <span className="relative">
                Business Playbook
                <HandDrawnUnderline color={theme.primaryColor} />
              </span>
            </h2>
            <p
              className="text-sm md:text-lg mx-auto leading-relaxed mb-2"
              style={{ color: theme.textColor + '80' }}
            >
              <i>
                SteerUp is a just-in-time equity and debt crowdfunding platform
                identifying business selections through lead investors who have
                skin in the game.
              </i>
            </p>

            <div className="flex flex-wrap items-center justify-center gap-2">
              <span
                className="inline-flex items-center px-2 py-1 rounded-full text-xs"
                style={{
                  backgroundColor: theme.additionalFundingBgColor,
                  color: 'var(--text-color)',
                  opacity: '0.55',
                }}
              >
                <BsRocket className="w-3 h-3 mr-1" />
                Pre-Launch
              </span>

              <span
                className="inline-flex items-center px-2 py-1 rounded-full text-xs"
                style={{
                  backgroundColor: theme.additionalFundingBgColor,
                  color: 'var(--text-color)',
                  opacity: '0.55',
                }}
              >
                <HiOutlineCurrencyDollar className="w-3 h-3 mr-1" />
                Self-Funded
              </span>

              <span
                className="inline-flex items-center px-2 py-1 rounded-full text-xs"
                style={{
                  backgroundColor: theme.additionalFundingBgColor,
                  color: 'var(--text-color)',
                  opacity: '0.55',
                }}
              >
                <FiMapPin className="w-3 h-3 mr-1" />
                Montreal
              </span>

              <div className="flex items-center gap-2 mt-3 justify-center w-full">
                <AudioPlayer
                  audioUrl={`/audio/business-playbooks/${businessName}/summary.mp3`}
                  theme={theme}
                />
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button
                      className="px-2 py-1 rounded-lg hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200 inline-flex items-center gap-1"
                      style={{
                        backgroundColor: theme.cardBgColor + 'ab',
                        color: theme.textColor + '80',
                      }}
                      aria-label="Share Business Playbook"
                    >
                      <BiShareAlt className="w-5 h-5" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="center"
                    className="min-w-[60px] rounded-md border shadow-md"
                    style={{
                      backgroundColor: theme.cardBgColor,
                      color: theme.textColor,
                      borderColor: theme.textColor + '50',
                    }}
                  >
                    <DropdownMenuItem asChild>
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(`${formattedBusinessName} - Business Playbook`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80"
                      >
                        <FiLinkedin className="w-4 h-4" />
                        <span>LinkedIn</span>
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <a
                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(`${formattedBusinessName} - Business Playbook`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80"
                      >
                        <FaXTwitter className="w-4 h-4" />
                        <span>X</span>
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?caption=${encodeURIComponent(`${formattedBusinessName} - Business Playbook`)}&u=${encodeURIComponent(window.location.href)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80"
                      >
                        <FaFacebook className="w-4 h-4" />
                        <span>Facebook</span>
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <a
                        href={`mailto:?subject=${encodeURIComponent(`${formattedBusinessName} - Business Playbook`)}&body=${encodeURIComponent(`${description}\n\n${window.location.href}`)}`}
                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80"
                      >
                        <FiMail className="w-4 h-4" />
                        <span>Email</span>
                      </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                          alert('Link copied to clipboard!');
                        }}
                        className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80 w-full text-left"
                      >
                        <BiCopy className="w-4 h-4" />
                        <span>Copy Link</span>
                      </button>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>

          <div className="space-y-8 md:space-y-12">
            {/* First Row: Winning Aspiration and Where to Play */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-12">
              {/* Winning Aspiration section - 1/3 width */}
              <div className="md:col-span-4">
                <WinningAspiration />
              </div>

              {/* Where to Play section - 2/3 width */}
              <div className="md:col-span-8">
                <WhereToPlay />
              </div>
            </div>

            {/* Second Row: How to Win (Path to Success) - Full width */}
            <div className="w-full">
              <HowToWin />
            </div>

            {/* Third Row: Profit Engine (2/3) and Key Assets (1/3) */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
              {/* Profit Engine - 2/3 width */}
              <div className="md:col-span-7">
                <ProfitEngine />
              </div>

              {/* Key Assets - 1/3 width */}
              <div className="md:col-span-5">
                <KeyAssets />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
