import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FiPlay, FiPause, FiCheck, FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const PLAYBACK_SPEEDS = [2, 1.75, 1.5, 1.25, 1, 0.75];

export default function AudioPlayer({ 
  audioUrl, 
  theme,
  buttonStyle = {}, // Custom button styles
  buttonClassName = "", // Additional button classes
  iconColor, // Custom icon color
  textColor // Custom text color
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const audioRef = useRef(null);
  const progressRef = useRef(null);

  const formatTime = useCallback((time) => {
    if (!time) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  const [currentTime, setCurrentTime] = useState("0:00");
  const [timeRemaining, setTimeRemaining] = useState("0:00");

  const updateProgress = useCallback(() => {
    if (audioRef.current) {
      const value = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(value);
      setCurrentTime(formatTime(audioRef.current.currentTime));
      setTimeRemaining(`-${formatTime(audioRef.current.duration - audioRef.current.currentTime)}`);
    }
  }, [formatTime]);

  useEffect(() => {
    const audio = new Audio(audioUrl);
    audioRef.current = audio;
    
    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('timeupdate', updateProgress);

    return () => {
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('timeupdate', updateProgress);
      audio.pause();
      audioRef.current = null;
    };
  }, [audioUrl, updateProgress]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play().catch(error => {
        console.error('Error playing audio:', error);
        alert('Failed to play audio summary. Please try again.');
      });
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const updateTimeFromEvent = useCallback((clientX) => {
    if (!audioRef.current || !progressRef.current) return;
    
    const rect = progressRef.current.getBoundingClientRect();
    const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
    const percentage = (x / rect.width) * 100;
    const time = (percentage / 100) * audioRef.current.duration;
    
    audioRef.current.currentTime = time;
    setProgress(percentage);
  }, []);

  const handleProgressClick = useCallback((e) => {
    updateTimeFromEvent(e.clientX);
  }, [updateTimeFromEvent]);

  const handleProgressTouch = useCallback((e) => {
    e.preventDefault(); // Prevent scrolling while dragging
    updateTimeFromEvent(e.touches[0].clientX);
  }, [updateTimeFromEvent]);

  const handleProgressTouchMove = useCallback((e) => {
    e.preventDefault(); // Prevent scrolling while dragging
    updateTimeFromEvent(e.touches[0].clientX);
  }, [updateTimeFromEvent]);

  const handleProgressTouchEnd = useCallback((e) => {
    e.preventDefault(); // Prevent any default touch end behavior
  }, []);

  const handleSpeedChange = useCallback((speed) => {
    setPlaybackSpeed(speed);
  }, []);

  const handleRewind = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 15);
    }
  }, []);

  const handleForward = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(
        audioRef.current.duration,
        audioRef.current.currentTime + 30
      );
    }
  }, []);

  if (!isExpanded) {
    return (
      <button
        onClick={() => {
          setIsExpanded(true);
          // Start playing immediately when expanded
          setTimeout(() => {
            if (audioRef.current) {
              audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
                alert('Failed to play audio summary. Please try again.');
              });
              setIsPlaying(true);
            }
          }, 0);
        }}
        className={`px-3 py-1 rounded-lg hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200 inline-flex items-center gap-1 ${buttonClassName}`}
        style={{ 
          backgroundColor: theme.cardBgColor + 'ab',
          ...buttonStyle 
        }}
        aria-label="Listen to AI Summary"
      >
        <FiPlay className="w-4 h-5" style={{ color: iconColor || theme.textColor + '80' }} />
        <span style={{ color: textColor || theme.textColor + '80' }}>Listen</span>
      </button>
    );
  }

  return (
    <div 
      className="flex flex-col gap-1 p-2 ml-3 rounded-lg w-[400px]"
      style={{ backgroundColor: theme.cardBgColor + 'ab' }}
    >
      <div className="flex flex-col gap-2">
        <div 
          ref={progressRef}
          className="w-full h-1 bg-gray-200 dark:bg-gray-700 rounded cursor-pointer relative touch-none"
          onClick={handleProgressClick}
          onTouchStart={handleProgressTouch}
          onTouchMove={handleProgressTouchMove}
          onTouchEnd={handleProgressTouchEnd}
        >
          <div
            className="h-full rounded-full transition-all duration-150"
            style={{ 
              width: `${progress}%`,
              backgroundColor: theme.primaryColor
            }}
          />
          <div 
            className="absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-white shadow-md transform -translate-x-1/2 touch-none"
            style={{
              left: `${progress}%`,
              borderColor: theme.primaryColor,
              borderWidth: '2px'
            }}
          />
        </div>
        <div className="flex justify-between text-xs" style={{ color: theme.textColor + '80' }}>
          <span>{currentTime}</span>
          <span>{timeRemaining}</span>
        </div>
      </div>

      <div className="flex items-center gap-4">
        {/* Playback Speed Selector */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
        <button
          className="px-3 py-1 rounded hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200 w-16 text-center text-base"
          style={{ color: theme.textColor + '80' }}
        >
          <span className="font-medium">{playbackSpeed}x</span>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="center"
            className="min-w-[60px] rounded-md border shadow-md"
            style={{ 
              backgroundColor: theme.cardBgColor,
              color: theme.textColor,
              borderColor: theme.textColor + '50'
            }}
          >
            {PLAYBACK_SPEEDS.map((speed) => (
              <DropdownMenuItem
                key={speed}
                className="flex items-center gap-2 px-4 py-2 text-sm cursor-pointer hover:bg-opacity-10 hover:bg-gray-500 opacity-80"
                onClick={() => handleSpeedChange(speed)}
              >
                {playbackSpeed === speed && <FiCheck className="w-4 h-4" />}
                <span className={playbackSpeed === speed ? 'ml-0' : 'ml-6'}>
                  {speed}x
                </span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        {/* Rewind 15s Button */}
        <button
          onClick={handleRewind}
          className="p-2 py-0 rounded-full hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200"
          style={{ color: theme.textColor + '80' }}
        >
          <div className="relative">
          <FiRotateCcw className="w-6 h-6" />
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[10px] font-medium">
              15
            </span>
          </div>
        </button>

        {/* Play/Pause Button */}
        <button
          onClick={handlePlayPause}
          className="p-2 py-0 rounded-full hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200"
          style={{ color: theme.primaryColor }}
        >
          {isPlaying ? <FiPause className="w-7 h-7" /> : <FiPlay className="w-7 h-7" />}
        </button>

        {/* Forward 30s Button */}
        <button
          onClick={handleForward}
          className="p-2 py-0 rounded-full hover:bg-opacity-10 hover:bg-gray-500 transition-colors duration-200"
          style={{ color: theme.textColor + '80' }}
        >
          <div className="relative">
          <FiRotateCw className="w-6 h-6" />
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[10px] font-medium">
              30
            </span>
          </div>
        </button>
      </div>
    </div>
  );
}
